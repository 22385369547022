import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../../slide/67m.png";
import Button from 'react-bootstrap/Button';




function Home4(){
    return(
            <div className="" >
            <Container style={{paddingTop:'6vw',paddingBottom:'8vw'}}>
                <Row>
                    <Col  xs="12"  xl="6" className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>

                    <Col  xs="12"  xl="6"  className="j1" style={{marginTop:'5vw'}}>   
                        <div style={{marginBottom:'26px',paddingLeft:'3vw'}}>
                            <p className="joinp" style={{color:'#861e12',fontSize:'20px',marginBottom:'1rem'}}>an agency or a hotel</p>

                            <h2 className="h2join">Consider Joining<br/>Our Community</h2>
                        
                        </div> 
                        <div style={{marginBottom:'34px'}}>
                            <p className="joinp1" style={{paddingLeft:'3vw'}}>

                            As travel resumes worldwide, seize the opportunity to connect with a larger audience 
                            through our marketplace. 
                            <br/> <br/>The universal appeal of travel attracts individuals to our 
                            platform, where a user-friendly interface and an extensive range of listings empower
                            travelers to confidently book their perfect stay.   

                            </p>
                        </div>
                        <>
                                    

                                    <Button href="/Join" size="xxl" id="boutidh"  style={{
                                        float: 'left',
                                        marginLeft: '3vw',
                                        padding: '20px 40px 20px 40px',
                                        width: '35%',
                                        letterSpacing: '2px',
                                        fontFamily: 'Cormorant Garamond',
                                        fontSize: '16px',
                                        border:'none',
                                        boxShadow: '0 0 0 1px #d9d0bd,0 2px 48px 0 rgba(0,0,0,.04) !important',
                                        
                                    }}  >
                                        JOIN US
                                    </Button>
                                    </>
                       
                        
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Home4;