import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sd1 from "../../slide/elc.jpg";
import Sd2 from "../../slide/blog-05.jpg";
import Sd6 from "../../slide/54m.png";
import Sd4 from "../../slide/visits.png";
import Sd5 from "../../slide/visiiii.png";
import Sd3 from "../../slide/visii.png";
import Button from 'react-bootstrap/Button';



function Home6(){
    return(
        <div className="home6" style={{position:'relative',zIndex:'100',marginTop:'-3vw'}}>
                    <div style={{marginBottom:'26px'}}>
                        <h2 className="h2join" style={{textAlign:'center',fontSize:'55px',paddingTop:'5vw',color:'#1e2031',letterSpacing:'2px'}}>Join Our Talent Community<br/>On different Platforms</h2>
                    </div> 
                    
                    <div style={{marginBottom:'32px',float:'right',paddingLeft:'12vw',paddingRight:'12vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>
                                Within the dynamic realm of the IDH Group, our diverse subsidiaries excel in their respective niches.
                                <br/> El Comparador-B2B provides an exclusive reservation platform for tourism 
                                professionals, while IDH Tours pioneers online travel booking with cutting-edge technology.
                                IDH Corporate stands out for simplifying business travel and event management,
                                offering unique and comprehensive solutions.<br/> Together, our subsidiaries embody 
                                a commitment to excellence and innovation across various sector
                            </p>
                    </div>
          
                    <Container className="scm1" >
                        <Row>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={Sd1} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">El Comparador</h2>
                                    <p className="pservice2">
                                    El Comparador-B2B is a reservation platform exclusively designed 
                                    for tourism professionals, including travel agencies, hotel chains, and 
                                    reservation centers
                                    
                                    </p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px',border:'none'}} size="xxl"  >
                                            <a href="https://www.elcomparador-b2b.com/" className="text-white">  READ MORE </a> 
                                            </Button>
                                    </>
                                </div>
                                            
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                            <div>
                                    <div className="ji" style={{padding:'10px'}}>
                                                        <div >
                                                                        <div className="jfi">
                                                                            <img src={Sd2} alt="hotelidh" className="jimg" />
                                                                        </div>
                                                        </div>
                                                        <div className="mlk" style={{display:'flex', flexDirection:'column',paddingBottom:'60px',fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'1'}}>
                                                            <a href="http://groupidh.com/" style={{margin:'auto'}}> <h3 className="mlh3" style={{fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'0',textAlign:'center'}}>groupidh.com</h3></a> 
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{margin:'auto',color:'white'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                                                        
                                                                        
                                                        </div>
                                    </div>
                                </div>
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={Sd3} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">IDH Tours</h2>
                                    <p className="pservice2">
                                    Discover the ultimate in online travel booking with IDH Tours. Harnessing state-of-the-art information 
                                    and communication technologies, we prioritize the evolving needs and expectations of the travel market. 
                                    Reserve your travel experiences at your convenience, from anywhere in the world
                                    
                                    </p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="http://srv2.elcomparador-b2b.com/hotels" className="text-white"> READ MORE </a>
                                            </Button>
                                            </>
                                </div>
                                            
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                            <div>
                                    <div className="ji" style={{padding:'10px'}}>
                                                        <div >
                                                                        <div className="jfi">
                                                                            <img src={Sd4} alt="hotelidh" className="jimg" />
                                                                        </div>
                                                        </div>
                                                        <div className="mlk" style={{display:'flex', flexDirection:'column',paddingBottom:'60px',fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'1'}}>
                                                            <a href="https://www.elcomparador-b2b.com/" style={{margin:'auto'}}> <h3 className="mlh3" style={{fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'0'}}>www.elcomparador-b2b.com</h3></a> 
                                                                <svg xmlns="http://www.w3.org/2000/svg" style={{margin:'auto',color:'white'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                                                        
                                                                        
                                                        </div>
                                    </div>
                                </div>
                            
                            </Col>
                            <Col  xl="4" lg="6" className="rescc" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={Sd5} alt="hotelidh" className="jimg" />
                                </div>
                                <a href="http://groupidh.com/"> </a> 
                                <div className="divdimg">
                                    <h2 className="h2s2">Group IDH</h2>
                                    <p className="pservice2">
                                    With over two decades of expertise, we specialize in offering travel services, 
                                    tourist products, and associated technological solutions for both business-to-business
                                    (B2B) and business-to-consumer (B2C) sectors
                                    
                                    </p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="http://groupidh.com/" className="text-white">  READ MORE </a>
                                            </Button>
                                            </>
                                </div>
                                        
                            
                            </Col>
                            <Col  xl="4" lg="6" className="r7" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={Sd6} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">IDH Corporate</h2>
                                    <p className="pservice2">
                                    Votre voyage d’affaires
                                    N'a jamais été aussi simple à faire 
                                    IDH Corporate est une expérience unique, dans le monde de l’évènementiel, qui 
                                    vous offre une solution exhaustive pour la réussite de votre événement professionnel sous toutes ses formes.
                                    Notre engagement, vous voir applaudir à la fin de votre événement qui marquera à coup sûr les esprits de 
                                    vos participants 
                                    
                                    </p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                            
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="https://www.idhcorporate.com/corporate" className="text-white" >   READ MORE </a>
                                            </Button>
                                            </>
                                </div>
                                            
                            
                            </Col>
                        </Row>
                    </Container>
        </div>

    );
}


export default Home6;