
function About1(){
    return(
       
            <div className="About1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">Amazing </span>
                            <h2 className="h3c">About Us</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
    );
}

export default About1;