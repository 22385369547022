import { Container } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dl from '../slide/service.png';
import dl1 from '../slide/ka.jpg';
import dl2 from '../slide/pol.png';
import dl3 from '../slide/98.jpg';
import dl33 from '../slide/vrtt.png';




function About4(){
    return(
        <div className="bac">
            <Container>
                <Row>
                    <Col lg="4" style={{padding:'1vw'}}>
                        <div style={{marginBottom:'10px'}}>
                            <p className="joinp" style={{color:'#861e12',marginBottom:'1rem'}}>The whole</p>
                            <h2 className="h2join" style={{marginBottom:'0vw'}}> World  in your hand   </h2>
                        </div> 
                        <div style={{marginBottom:'10px',paddingLeft:'0px'}}>
                            <p className="joinp11">

                            Embarking on a collaborative venture, we aim to  <br/> 
                            propel travel technology to greater heights together.<br/><br/>
                            With a shared commitment to innovation, our journey is <br/>dedicated to pushing 
                            the boundaries of what's possible. 


                       
                            </p>
                        </div>
                    </Col>
                    <Col  lg="4" >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3" style={{}}>100% Trusted Tour Agency</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"  >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl1} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">Over 1 Million Hotels</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"  >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl2} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">+20 Years of service</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"    >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl3} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">+7000 Stunning Places</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4" style={{marginTop:'-5vw'}} >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl33} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">100% customer satisfaction</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>  
                </Row>
            </Container>
        </div>
    );
}

export default About4;