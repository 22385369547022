import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../../slide/977.png";
import Button from 'react-bootstrap/Button';



function Home2(){
    return(
            <div className="qsd1111">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                    <Col  xs="12"  xl="6" className="j1" style={{ marginTop:'1vw',display:'flex', flexDirection:'column'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',textAlign:'center',fontSize:'14px'}}>HOTELIDH PLATFORM</p>
                            <h2 className="h2join2" style={{textAlign:'center',lineHeight:'90px'}}>Most Complete <br/>Booking Platform</h2>
                          
                        </div> 
                        <div style={{marginBottom:'32px',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>
                            Hotelidh, a leading B2B reservation platform, curated by seasoned professionals <br/> and dedicated tourism 
                            enthusiasts, stays abreast of evolving market trends. Backed by significant investments, 
                            our online infrastructure ensures optimal performance and user friendliness,  
                            offering diverse options tailored
                                 to each client's <br/> distinct needs and economic models.
                            </p>
                        </div>
                            <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{padding:'10px',margin:'auto', fontSize:'0.9rem', width:'25%',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="about" className="text-white"> ABOUT US</a>
                                    </Button>
                            </>
                       
                        
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Home2;