import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../../slide/99977.png";




function Home3(){
    return(
            <div className="" style={{}}>
            <Container style={{paddingTop:'4vw',paddingBottom:'4vw',display:'flex',flexDirection:'column'}}>
                <Row>
                    

                    <Col sm={12} className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                           
                            <h2 className="h2join" style={{textAlign:'center',fontSize:'55px',letterSpacing:'2px'}}>
                                By Partnering With <br/> Hotelidh You Will Benefit From</h2>
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'12vw',paddingRight:'12vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>

                                Hotelidh stands as the ultimate reservation platform tailored for key players in the tourism sector, encompassing travel agencies, prestigious hotel chains, and reservation centers. Our platform provides a rich and tailored experience, offering these professionals a comprehensive range of services and features. Whether you are a travel agency, a hotel chain, or a reservation center, Hotelidh is committed to streamlining your operations, optimizing performance, and fostering growth in a constantly evolving industry
                            </p>
                        </div>
                            
                    </Col>
                    <Col  className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD}  alt="hotelidh"  />
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Home3;