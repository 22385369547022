import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../../slide/66m.png";
import Button from 'react-bootstrap/Button';



function Home5(){
    return(
            <div className="">
            <Container style={{paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'2vw',marginBottom:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#861e12',fontSize:'20px',marginBottom:'1rem'}}>Discover Our Solutions</p>

                            <h2 className="h2join">Uncover Our Offer<br/>of Services</h2>
                        </div> 
                        <div style={{marginBottom:'34px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}} >

                                Maximize your visibility to millions of individuals preparing for their next travel adventure by featuring
                                your services on our marketplace. If you're a travel agency,
                                we facilitate meaningful connections between you and travelers actively seeking your offerings.
                                    <br/> <br/>
                                Our commitment goes beyond providing a platform; we furnish essential booking tools, 
                                offer valuable insights, and deliver dedicated support. For hotels, the Hotelidh platform offers
                                an advanced management system, optimizing operations and linking 
                                with over 5000 travel agencies globally.
                            

                            </p>
                        </div>
                                <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                   
                                    color: #fff;
                                   
                                 
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.09rem;
                                    background-color:#772828;
                                    padding: 20px 40px 20px 40px;
                                    border-radius: 0px;
                                    }
                                    `}
                                    </style>

                                    <Button href="/Services" size="xxl" id="boutidh"  style={{
                                        float: 'left',
                                        padding: '20px 40px 20px 40px',
                                        width: '35%',
                                        letterSpacing: '2px',
                                        fontFamily: 'Cormorant Garamond',
                                        fontSize: '16px',
                                       
                                        
             
                                        boxShadow: '0 0 0 1px #d9d0bd,0 2px 48px 0 rgba(0,0,0,.04) !important',
                                        
                                    }}  >
                                        Services
                                    </Button>
                                </>
                       
                        
                    </Col>
                    <Col xs="12"  xl="6" className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Home5;